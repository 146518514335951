exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-templates-academy-template-js": () => import("./../../../src/templates/academy-template.js" /* webpackChunkName: "component---src-templates-academy-template-js" */),
  "component---src-templates-cpq-blog-archive-js": () => import("./../../../src/templates/cpq-blog-archive.js" /* webpackChunkName: "component---src-templates-cpq-blog-archive-js" */),
  "component---src-templates-cpqpost-js": () => import("./../../../src/templates/cpqpost.js" /* webpackChunkName: "component---src-templates-cpqpost-js" */),
  "component---src-templates-customer-story-archive-js": () => import("./../../../src/templates/customer-story-archive.js" /* webpackChunkName: "component---src-templates-customer-story-archive-js" */),
  "component---src-templates-customerstory-js": () => import("./../../../src/templates/customerstory.js" /* webpackChunkName: "component---src-templates-customerstory-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-archive-js": () => import("./../../../src/templates/events-archive.js" /* webpackChunkName: "component---src-templates-events-archive-js" */),
  "component---src-templates-newspost-js": () => import("./../../../src/templates/newspost.js" /* webpackChunkName: "component---src-templates-newspost-js" */),
  "component---src-templates-on-demand-webinars-js": () => import("./../../../src/templates/on-demand-webinars.js" /* webpackChunkName: "component---src-templates-on-demand-webinars-js" */),
  "component---src-templates-partner-archive-js": () => import("./../../../src/templates/partner-archive.js" /* webpackChunkName: "component---src-templates-partner-archive-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-press-release-archive-js": () => import("./../../../src/templates/press-release-archive.js" /* webpackChunkName: "component---src-templates-press-release-archive-js" */),
  "component---src-templates-research-report-archive-js": () => import("./../../../src/templates/research-report-archive.js" /* webpackChunkName: "component---src-templates-research-report-archive-js" */),
  "component---src-templates-researchreport-js": () => import("./../../../src/templates/researchreport.js" /* webpackChunkName: "component---src-templates-researchreport-js" */)
}

